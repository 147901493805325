import {Typography} from "@material-ui/core";
import React from "react";
import Card from "@material-ui/core/Card";
import BuildIcon from '@material-ui/icons/Build';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    card: {
        padding: '1em',
        margin: '1em',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        [theme.breakpoints.only('sm')]: {
            width: '50%'
        },
        [theme.breakpoints.up('md')]: {
            width: '30%'
        }
    },
    cardIcon: {
        'padding-left': '.25em'
    },
    cardText: {
        display: 'inline-flex',
        alignItems: 'center',
    }
}));

export default function Landing() {
    const classes = useStyles();
    return (
        <div>
            <Card className={classes.card} elevation={2}>
                <Typography className={classes.cardText}>
                    Engineer
                    <BuildIcon className={classes.cardIcon}/>
                </Typography>
            </Card>
            <Card className={classes.card} elevation={2}>
                <Typography className={classes.cardText}>
                    Outdoorsman
                    <NaturePeopleIcon className={classes.cardIcon}/>
                </Typography>
            </Card>
            <Card className={classes.card} elevation={2}>
                <Typography className={classes.cardText}>
                    Student
                    <LocalLibraryIcon className={classes.cardIcon}/>
                </Typography>
            </Card>
        </div>
    );
}