import React from "react";
import {Avatar, CardActionArea, CardContent, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import NpmIcon from "./NpmIcon";

const useStyles = makeStyles(theme => ({
    card: {
        padding: '1em',
        margin: '1em',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        [theme.breakpoints.only('sm')]: {
            width: '50%'
        },
        [theme.breakpoints.up('md')]: {
            width: '30%'
        }
    },
    cardIcon: {
        'padding-left': '.25em'
    },
    cardText: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    cardActionArea: {
        textAlign: "left"
    }
}));

export default function Landing() {
    const classes = useStyles();

    return (
        <div>
            <Card className={classes.card} elevation={2}>
                <CardActionArea href="https://www.npmjs.com/package/@spenserca/arm-template-generator" className={classes.cardActionArea}>
                    <CardHeader
                        avatar={
                            <Avatar>
                                <NpmIcon fontSize="large"/>
                            </Avatar>
                        }
                        title="arm-template-generator"
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            This package allows you to write individual ARM template resource files as javascript or
                            json and compiles those files into a single ARM template for you!
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    )
}