import { Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import React from 'react';

const useStyles = makeStyles(theme => ({
  button: {
    margin: '.25em'
  },
  socialMediaIcon: {
    height: '32px',
    width: '32px',
    color: '#000000'
  }
}));

const renderMediumSocialMediaNavigation = (classes: any) => {
  return (
    <>
      <Grid item md={1}>
        <IconButton
          href='https://github.com/spenserca'
          className={classes.button}
        >
          <GitHubIcon className={classes.socialMediaIcon}/>
        </IconButton>
      </Grid>
      <Grid item md={1}>
        <IconButton
          href='https://www.linkedin.com/in/spenser-andreassen-b3410273/'
          className={classes.button}
        >
          <LinkedInIcon className={classes.socialMediaIcon}/>
        </IconButton>
      </Grid>
    </>
  );
};

const renderLargeSocialMediaNavigation = (classes: any) => <Grid item lg={2}>
  <IconButton
    href='https://github.com/spenserca'
    className={classes.button}
  >
    <GitHubIcon className={classes.socialMediaIcon}/>
  </IconButton>
  <IconButton
    href='https://www.linkedin.com/in/spenser-andreassen-b3410273/'
    className={classes.button}
  >
    <LinkedInIcon className={classes.socialMediaIcon}/>
  </IconButton>
</Grid>;

export const SocialMediaNavigation: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.only('md'));

  return (
    <>
      {isMedium
        ? renderMediumSocialMediaNavigation(classes)
        : renderLargeSocialMediaNavigation(classes)
      }
    </>
  );
};
