import {Grid, List, ListItem} from '@material-ui/core';
import React from 'react';
import NavigationButton from "./NavigationButton";

export const VerticalNavigation: React.FC = () => {
    return (
        <Grid container direction='column' justify='center' alignItems='center'>
            <Grid item xs={12}>
                <List component='nav'>
                    <ListItem>
                        <NavigationButton path={'/experience'} text={'experience'}/>
                    </ListItem>
                    <ListItem>
                        <NavigationButton path={'/projects'} text={'projects'}/>
                    </ListItem>
                    <ListItem>
                        <NavigationButton path={'https://github.com/spenserca'} text={'Github'}/>
                    </ListItem>
                    <ListItem>
                        <NavigationButton path={'https://www.linkedin.com/in/spenser-andreassen-b3410273/'}
                                          text={'LinkedIn'}/>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};
