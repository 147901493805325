import {AppBar, Avatar, Grid, Toolbar, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import spenser from '../assets/spenserca.jpg';
import {HorizontalNavigation} from './navigation/HorizontalNavigation';
import {VerticalNavigation} from './navigation/VerticalNavigation';
import Button from "@material-ui/core/Button";
import grey from '@material-ui/core/colors/grey'

const useStyles = makeStyles(theme => ({
    avatar: {
        height: '128px',
        width: '128px',
        border: `3px solid #000000`,
        margin: '1em'
    },
    appBar: {
        backgroundColor: grey[50]
    }
}));

const getDirection = (isNarrow: boolean) => isNarrow ? 'column' : 'row';

const getContentJustification = (isNarrow: boolean) => isNarrow ? 'center' : 'flex-start';

const getContentAlignment = (isNarrow: boolean) => isNarrow ? 'center' : 'flex-end';

const Header: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <AppBar position='static' className={classes.appBar}>
            <Toolbar>
                <Grid container direction={getDirection(isNarrow)}
                      justify={getContentJustification(isNarrow)}
                      alignItems={getContentAlignment(isNarrow)}>
                    <Grid item xs={12} md={2}>
                        <Button href='/'>
                            <Avatar
                                alt='Spenser Andreassen'
                                src={spenser}
                                className={classes.avatar}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        {isNarrow ?
                            <VerticalNavigation/> :
                            <HorizontalNavigation/>
                        }
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
