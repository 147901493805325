import React from "react";
import SourceAlliesContent from "./SourceAlliesContent";
import PrincipalContent from "./PrincipalContent";

export default function Landing() {
    return (
        <div>
            <SourceAlliesContent/>
            <PrincipalContent/>
        </div>
    )
}