import {Button} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    button: {
        margin: '.25em'
    }
}));


interface NavigationButtonProps {
    path: string;
    text: string;
}

export default function NavigationButton({path, text}: NavigationButtonProps) {
    const classes = useStyles();

    return (
        <Button href={path} className={classes.button}>
            {text}
        </Button>
    );
};
