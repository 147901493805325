import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import {CardContent, Typography} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
    card: {
        padding: '1em',
        margin: '1em',
        textAlign: 'start',
        [theme.breakpoints.up('md')]: {
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }
}));

export default function SourceAlliesContent() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    function renderCollapseButton() {
        return <IconButton
            className={classes.expand}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show less"
        >
            <RemoveIcon/>
        </IconButton>;
    }

    function renderExpandButton() {
        return <IconButton
            className={classes.expand}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
        >
            <AddIcon/>
        </IconButton>;
    }

    return (
        <Card className={classes.card}  elevation={2}>
            <CardHeader title={"Source Allies"} subheader={"Software Engineering Consultant May 2017 - Present"}
                        action={
                            expanded ?
                                renderCollapseButton()
                                :
                                renderExpandButton()
                        }
            >
            </CardHeader>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography paragraph>
                        Principal Financial Group (Actuarial Modernization)
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText>
                                Automate manual actuarial processes used to generate files for model input
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Build data lake for actuarial processes to consume
                            </ListItemText>
                        </ListItem>
                    </List>

                    <Typography paragraph>
                        Principal Financial Group (Life Insurance/Retirement Services)
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText>
                                Rewrote legacy desktop application into modern Angular web application
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Consumed and processed Adobe Sign webhook payloads for increased visibility into
                                form
                                statuses
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Migrate FoxPro code into modern Java backend API for managing retirement account
                                data
                            </ListItemText>
                        </ListItem>
                    </List>

                    <Typography paragraph>
                        Corteva
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText>
                                Built mobile app platform to track buying, selling and shipping of seed
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Created APIs for third party integrations with application data
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Generated business reporting data from data warehouse
                            </ListItemText>
                        </ListItem>
                    </List>
                </CardContent>
            </Collapse>
        </Card>
    )
}