import CardHeader from "@material-ui/core/CardHeader";
import {CardContent} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles(theme => ({
    card: {
        padding: '1em',
        margin: '1em',
        textAlign: 'start',
        [theme.breakpoints.up('md')]: {
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }
}));

export default function PrincipalContent() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    function renderCollapseButton() {
        return <IconButton
            className={classes.expand}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show less"
        >
            <RemoveIcon/>
        </IconButton>;
    }

    function renderExpandButton() {
        return <IconButton
            className={classes.expand}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
        >
            <AddIcon/>
        </IconButton>;
    }

    return (
        <Card className={classes.card} elevation={2}>
            <CardHeader title={"Principal Financial Group"}
                        subheader={"Application Analyst II May 2014 - May 2017"}
                        action={
                            expanded ?
                                renderCollapseButton()
                                :
                                renderExpandButton()
                        }
            >
            </CardHeader>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <List>
                        <ListItem>
                            <ListItemText>
                                Integrated ACH and credit card processing into websites for online enrollment
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Extracted valuable data for business reporting and marketing
                            </ListItemText>
                        </ListItem>
                    </List>
                </CardContent>
            </Collapse>
        </Card>
    )
}