import React from 'react';
import './App.css';
import Header from './components/Header';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Experience from './components/experience/Landing';
import Projects from './components/projects/Landing';
import Home from "./components/home/Landing";
import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from '@material-ui/core/colors/grey'


const useStyles = makeStyles(theme => ({
    app: {
        textAlign: 'center',
        backgroundColor: grey[100]
    }
}));

function App() {
    const classes = useStyles();

    return (
        <Router>
            <div className={classes.app}>
                <Header/>
                <Switch>
                    <Route path='/experience'>
                        <Experience/>
                    </Route>
                    <Route path='/projects'>
                        <Projects/>
                    </Route>
                    <Route path='/'>
                        <Home/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
