import {Button, Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import {SocialMediaNavigation} from './SocialMediaNavigation';
import NavigationButton from "./NavigationButton";

const useStyles = makeStyles(theme => ({
  navigationButtonContainer: {
    textAlign: 'start'
  },
  button: {
    margin: '.25em'
  }
}));

const renderNavigationLinks = (classes: any) => {
  return (
    <Grid item xs={10} className={classes.navigationButtonContainer}>
        <NavigationButton path={'/experience'} text={'experience'} />
        <NavigationButton path={'/projects'} text={'projects'} />
    </Grid>
  );
};

export const HorizontalNavigation: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      {renderNavigationLinks(classes)}
      <SocialMediaNavigation />
    </Grid>
  );
};
